import { RawHtml } from '@finn/platform-modules';
import { CTA } from '@finn/ui-components';
import { parseToHtml, useIntl } from '@finn/ui-utils';
import trim from 'lodash/trim';
import React, { ReactNode } from 'react';

import {
  GuideMetaData,
  TextblockAddProps,
  TextblockCosmicMetadata,
} from '~/types/UIModules';
import SocialSharing from '~/ui-modules/Hero/SocialSharing/SocialSharing';

type Props = {
  data: TextblockCosmicMetadata;
  onCTAClick?: Function;
  addProps?: TextblockAddProps;
  children?: ReactNode;
};

const hasRelevantMetaField = (guideMetaData: GuideMetaData) => {
  return trim(guideMetaData?.photos_by) || trim(guideMetaData?.written_by);
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const Textblock = ({
  data,
  onCTAClick = () => {},
  addProps,
  children,
}: Props) => {
  const { title, description, cta, guide_meta_data } = data;
  const { noticeTitle, noticeDescription, trackDynamicSection, containerRef } =
    addProps || {};
  const guideMetaData = guide_meta_data?.metadata;
  const intl = useIntl();

  return (
    <div className="container">
      <div className="md:grid md:grid-cols-[5fr,7fr] md:gap-6">
        <h2 className="mobile-t3-semibold md:web-t3-semibold mb-6 md:mb-0">
          {parseToHtml(title)}
        </h2>
        <div>
          <div className="body-14-regular">
            <RawHtml html={description} useGlobalStyles fullWidthMedia />
            <ConditionalWrapper
              condition={trackDynamicSection}
              wrapper={(wrapperChildren) => (
                <div ref={containerRef}>{wrapperChildren}</div>
              )}
            >
              {noticeTitle && (
                <>
                  <span className='global-t5-semibold whitespace-pre-wrap before:content-["\\a\\a"] after:content-["\\a\\a"]'>
                    {noticeTitle}
                  </span>
                  <span>{noticeDescription}</span>
                </>
              )}
            </ConditionalWrapper>
          </div>
          {(cta?.metadata?.text || addProps?.ctaText) && cta.metadata && (
            <div className="mg-6 md:mt-8">
              <CTA
                data={cta.metadata}
                addProps={addProps}
                onCTAClick={onCTAClick}
              />
            </div>
          )}
          {hasRelevantMetaField(guideMetaData) ? (
            <div className="border-pearl mt-16 flex flex-wrap border-0 border-t border-solid pt-6">
              {guideMetaData.written_by ? (
                <div className="basis-1/2 pb-3 md:basis-1/4 md:pb-0">
                  <h6 className="global-t6-semibold mb-2">
                    {intl.formatMessage('general.guideWrittenBy')}
                  </h6>
                  <span className="body-14-regular">
                    {guideMetaData.written_by}
                  </span>
                </div>
              ) : null}
              {guideMetaData.photos_by ? (
                <div className="basis-1/2 pb-3 md:basis-1/4 md:pb-0">
                  <h6 className="global-t6-semibold mb-2">
                    {intl.formatMessage('general.guidePhotosBy')}
                  </h6>
                  <span className="body-14-regular">
                    {guideMetaData.photos_by}
                  </span>
                </div>
              ) : null}
              <div className="basis-1/2 pb-3 md:basis-1/4 md:pb-0">
                <h6 className="global-t6-semibold mb-2">
                  {intl.formatMessage('general.guideShare')}
                </h6>
                <SocialSharing />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Textblock;
